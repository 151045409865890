<template>
  <section class="sign-up text-white">
    <div class="center max-width">
      <div>
        <p class="easy-text sign-up-text">Simples e fácil</p>
        <h4>Divulgue marcas e tenha uma renda extra</h4>
        <p class="sign-up-text">
          Faça parte do <strong>Flip.net</strong> e receba comissões que podem
          ultrapassar
          <strong>10.000 reais mensais.</strong>
        </p>
        <FormComponent
          :utmParams="utmsParams"
          id="form-signup"
          v-if="isMobile"
          class="custom-component"
        />
        <div class="sign-up-description">
          <q-separator vertical class="q-mr-md" />
          <div>
            <p>A gente te ajuda a crescer</p>
            <p>
              Ao se cadastrar, você terá todo o suporte e conhecimento
              necessários para a alcançar suas metas!
            </p>
          </div>
        </div>
      </div>
      <FormComponent
        :utmParams="utmsParams"
        id="form-signup"
        v-if="!isMobile"
        :style="!isMobile ? 'margin-top: -1rem' : ''"
        style="max-width: 364px; min-height: 600px"
        class="custom-component"
      />
    </div>
  </section>
</template>
<script setup>
import FormComponent from "../../modules/auth/pages/signup-blog-flipnet/components/FormComponent.vue";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";

const utmsParams = ref({});

const $q = useQuasar();

const isMobile = computed(() => {
  return $q.screen.lt.sm;
});

// Recebe os parametros utm do site principal
const receiveUrlParams = () => {
  // Ouve as mensagens da janela principal
  window.addEventListener("message", (event) => {

    // Verifica a origem da mensagem
    if (event.origin == "https://flip.net.br") {
      if (event.data.type === "urlParamsSignUp") {
        const getUtmParams = event.data.params;

        utmsParams.value = getUtmParams;
      }
    }
  });
};

onMounted(() => {
  receiveUrlParams();
});
</script>
<style scoped>
    :deep(.form-step-three .q-field--error .q-field__bottom) {
        padding-top: 7px !important;
    }

    :deep(.form-step-three .input-caption) {
        padding-top: 13px !important;
    }
</style>
<style>
.sign-up {
  background-image: url("../../assets/background-blog-lp-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 49px 19px 30px 19px;
  position: relative;
}

.sign-up .center {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  gap: 30px;
}

.sign-up .center .custom-component .q-field__inner {
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

.sign-up .center .custom-component .q-field--dense .q-field__control,
.sign-up .center .custom-component .q-field--dense .q-field__control input {
  font-size: 12px;
  height: 30px;
}

.sign-up .center .custom-component {
  padding: 10px;
}

.sign-up .center .custom-component .form-bottom-component {
  position: absolute;
  bottom: 0px;
}

.sign-up .center .custom-component .input-label,
.sign-up .center .custom-component .input-caption {
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #111928;
}

.sign-up .center .custom-component .input-caption {
    color: #6B7280;
    line-height: 1.3;
    padding-top: 7px;
}

.sign-up .center .custom-component .q-field--with-bottom {
  padding-bottom: 0px;
}

.sign-up .center .custom-component .q-field__control {
  color: var(--flip-color-primary);
}

.sign-up .center .custom-component .q-field--error .q-field__bottom {
  padding-top: 10px;
  padding-left: 0px;
}

.sign-up .center .custom-component .q-field--dense .q-field__label {
  top: 5px;
  font-size: 11px;
}

.sign-up .center > div:first-child p.easy-text {
  font-size: max(20px, min(4vw, 23px));
}

.sign-up .center > div:first-child p.sign-up-text {
  color: #fffdfd;
}

.sign-up .center > div:first-child p.sign-up-text:not(.easy-text) {
  font-size: max(14px, min(4vw, 23px));
  font-weight: 300;
}

.sign-up .center .q-card__section {
  padding-top: 0px;
}

.sign-up .center .q-card__section div.q-mb-md > span {
  font-size: 22px !important;
  text-transform: normal;
}

.q-card > p,
.q-card > p > a {
  font-size: 14px;
}

.q-card > p > span {
  color: #000 !important;
}

.q-card .btn-my-account {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.q-card .btn-my-account > a {
  font-weight: 800;
  color: var(--flip-color-primary) !important;
  padding-left: 5px;
}

.q-card .btn-my-account > a:hover {
  text-decoration: underline;
}

.sign-up .center .q-card__section .q-checkbox__inner {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
}

.sign-up .center .custom-component .option-group > div {
  margin-left: 0px;
}

.sign-up .center .q-card__section a {
  height: fit-content;
}

.sign-up .center .q-card__section .q-btn {
  margin-top: 17px;
  font-family: "Montserrat", sans-serif !important;
  color: #000;
  height: 40px;
}

.sign-up .center .custom-component .form-step-two .q-mt-sm {
  font-size: 11px;
  margin-top: 20px;
}

.sign-up .center .custom-component .form-step-success .text-first-access p {
  font-size: 14px;
  margin-bottom: 15px;
}

.sign-up .center .custom-component .form-step-success a {
  display: flex;
  color: #000 !important;
  text-decoration: underline;
  padding-left: 0px;
  justify-content: center;
  font-size: 14px;
}

.sign-up .center .custom-component .form-step-success .text-center {
  font-size: 14px;
}

.sign-up .center .q-card__section .q-btn.next {
  background: var(--flip-color-primary) !important;
  border-radius: 40px;
  color: #fff;
}

.sign-up h4 {
  margin: 25px 0px 15px 0px;
  font-size: max(36.98px, min(5vw, 62px));
  font-weight: bold;
  color: #fffcfc;
  line-height: 1.2;
}

.sign-up .title-sign-up {
  margin-bottom: 27px;
}

.sign-up .q-gutter-md .q-anchor--skip {
  color: #000 !important;
  font-size: 10px;
  font-weight: 600;
}

.sign-up .center .custom-component .q-gutter-md > div {
  margin-top: 20px;
}

.sign-up .center .custom-component .q-gutter-md > div label.q-field--error {
  padding-bottom: 20px;
}

.sign-up .center .custom-component .q-gutter-md > div:last-child {
  margin-top: 20px;
}

.sign-up .q-gutter-md .q-anchor--skip .q-icon {
  font-size: 14px;
  position: relative;
  top: -0.3rem;
}

.sign-up .title-sign-up,
.sign-up .center h2.title-sign-up p {
  font-size: max(20px, min(5vw, 22px));
  line-height: 1.5;
  font-weight: 500;
  color: #000 !important;
}

.title-sign-up span {
  color: var(--flip-color-primary);
  font-weight: 700;
  text-transform: uppercase;
}

.center .sign-up-description {
  display: flex;
  padding: 10.76px 16.13px;
  align-items: center;
  background-color: #fff;
  border-radius: 8.61px;
  justify-content: space-around;
}

.center .sign-up-description div p:first-child {
  color: #1d1e2c;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 900;
  font-size: max(14px, min(3vw, 22px));
}

.center .sign-up-description > div p:last-child {
  color: #000000;
  font-size: max(12px, min(3vw, 15px));
  font-weight: normal;
  margin-top: 5px;
}

@media (max-width: 599px) {
  .sign-up .center > div:first-child p.sign-up-text {
    margin-bottom: 25px;
  }

  .center .sign-up-description {
    margin-top: 27px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .sign-up .center > div:last-child {
    width: 100%;
  }
}

@media (min-width: 599.99px) {
  .sign-up h4 {
    margin: 38px 0px 28px 0px;
  }

  .center .sign-up-description {
    margin-top: 70px;
  }

  .sign-up {
    padding: 49px;
  }

  .sign-up .auth-content {
    margin-top: -2.5rem;
  }

  .sign-up .center > div:first-child {
    width: 80%;
    max-width: 559px;
  }

  .sign-up > div > p {
    max-width: 400px;
  }

  .sign-up-description {
    max-width: 573px;
  }
}

@media (min-width: 768px) {
  .sign-up {
    padding: 49px 19px 30px 19px;
  }
}
</style>
